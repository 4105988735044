export default {
    CHANGE: "CHANGE",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    GET_VIDEOS: "GET_VIDEOS",
    GET_VIDEOS_RESPONSE: "GET_VIDEOS_RESPONSE",
    ADD_VIDEO: "ADD_VIDEO",
    DELETE_VIDEO: "DELETE_VIDEO",
    EDIT_VIDEO: "EDIT_VIDEO",
    GET_BOOKS: "GET_BOOKS",
    GET_BOOKS_RESPONSE: "GET_BOOKS_RESPONSE",
    ADD_BOOK: "ADD_BOOK",
    DELETE_BOOK: "DELETE_BOOK",
    EDIT_BOOK: "EDIT_BOOK",
    GET_POSTS: "GET_POSTS",
    GET_POSTS_RESPONSE: "GET_POSTS_RESPONSE",
    GET_SINGLE_POST: "GET_SINGLE_POST",
    GET_SINGLE_POST_RESPONSE: "GET_SINGLE_POST_RESPONSE",
    GET_IMAGES: "GET_IMAGES",
    GET_IMAGES_RESPONSE: "GET_IMAGES_RESPONSE",
    GET_EVENTS: "GET_EVENTS",
    GET_EVENTS_RESPONSE: "GET_EVENTS_RESPONSE",
    ADD_EVENT: "ADD_EVENT",
    DELETE_EVENT: "DELETE_EVENT",
    EDIT_EVENT: "EDIT_EVENT",
};
